<style lang="scss">
#service-container-app {
  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  }
}
.selected-location {
  color: black;
  border: 1px solid black;
  line-height: 20px;
  padding-left: 44px;
  font-weight: bold;
  transform: scale(1.1);
  z-index: 9;
}
</style>
<template lang="">
 
  <div
    class="bg-light-default rounded p-5"
    id="service-container-app"
    :class="[getLocationId == location.id ? 'selected-location' : '']"
    @click="openService(location.id)"
  >
    <div class="d-flex align-items-center">
      <div class="symbol symbol-60 symbol-2by3 flex-shrink-0 mr-4">
        <div
          class="symbol-label"
          :style="
            'background-size: contain;background-image: url(' +
              url +
              location.image +
              ')'
          "
        ></div>
      </div>
      <div class="d-flex flex-column flex-grow-1">
        <a
          class="text-dark-cust font-weight-bolder font-size-lg text-hover-primary-cust mb-1"
          >{{ location.name }}</a
        ><span class="text-dark-50 font-weight-normal font-size-sm">
          {{ location.description }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { SET_LOCATION_ID } from "@/core/services/store/mutations.type";
import { mapGetters } from "vuex";

export default {
  props: {
    url: {
      type: String,
      default: "",
    },
    index: {
      type: Number,
      default: 0,
    },

    location: {
      type: Object,
      default: function () {
        return {
          id: 1,
          name: "Service Title",
          image: "/media/stock-600x400/img-20.jpg",
          description: "Description Here",
        };
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getLocationId"]),
  },
  methods: {
    openService(id) {
      this.$store.commit(SET_LOCATION_ID, id);
      this.$emit("locationIndex", this.index);
    },
  },
};
</script>
