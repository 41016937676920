<template>
  <section
    class=" d-flex flex-column flex-column-fluid new-service"
    id="f-book-now"
    style="width: calc(90vw - 20%) !important;"
  >
    <div class="sticky-t-details">
      <div class="col-lg-12">
        <div
          v-if="errorMsg != ''"
          role="alert"
          class="
                alert alert-custom alert-danger-dim alert-shadow
                fade
                show
                gutter-b
                alert-fixed
              "
        >
          <div class="alert-text">
            {{ errorMsg }}
          </div>
        </div>
        <h6>Applicant No : {{ getapplicantdetail.id }}</h6>

        <h6>
          Applicant Name : {{ getapplicantdetail.fname }}
          {{ getapplicantdetail.lname }}
        </h6>
        <h4 class="page-info mt-5">
          Please select a Locations
        </h4>
      </div>
    </div>
    <div class="col-md-12 mb-5 text-center" v-if="dataLoading">
      <loading-spinner style="margin-top:20px"> </loading-spinner>
      <h5 class="mt-3">Please wait!</h5>
    </div>
    <div class="row">
      <div
        class="col-lg-4 col-md-4 col-sm-12 mb-5"
        v-for="(location, index) in locations"
        :key="index"
        v-show="locations.length > 0 && !dataLoading"
      >
        <location-card
          :url="base_url"
          :index="index"
          :location="location"
          @locationIndex="locationIndex"
        ></location-card>
      </div>

      <div
        class="col-md-12 mb-5 text-center"
        v-show="locations.length <= 0 && !dataLoading"
      >
        <v-no-record></v-no-record>
      </div>
    </div>
    <div
      v-show="!dataLoading"
      id="btn-footer"
      class="bottom-nav"
      style="position: fixed; bottom: 15px; width: calc(74vw)"
    >
      <button class="btn btn-success mr-2 mt-3 btn-right" @click="submit()">
        Submit
      </button>
      <button class="btn btn-dark mr-2 mt-3 btn-right" @click="goback()">
        Go Back
      </button>
    </div>
  </section>
</template>
<script>
import loadingSpinner from "@/components/frontend/spinner.vue";
import vNoRecord from "@/components/frontend/norecord";
import LocationCard from "@/components/frontend/locationcard";

import {
  SET_BREADCRUMB,
  SET_LOCATION_ID
} from "@/core/services/store/mutations.type";

import {
  LOCATIONS,
  NEWLOCATIONSTORE
} from "@/core/services/store/actions.type";
import { mapGetters } from "vuex";

export default {
  components: {
    vNoRecord,
    loadingSpinner,
    LocationCard
  },
  mounted() {
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);

    this.fetchLocations();
  },
  data() {
    return {
      errorMsg: [],
      current_page: 1,
      dataLoading: false,
      locations: [],
      base_url: [],
      selected_item: {},
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Appointment List",
          route: ""
        },
        {
          id: 3,
          title: "Change Location",
          route: ""
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["getLocationId", "getapplicantdetail"])
  },

  filters: {},
  methods: {
    goback() {
      this.$store.commit(SET_LOCATION_ID, "");

      this.$router.go(-1);
    },
    submit() {
      if (this.getLocationId == "") {
        this.$toastr.e("Please select location");
      } else {
        this.$swal({
          title: "Are you sure?",
          text:
            "you want to change location to " + this.selected_item.name + " ?",
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
          showCancelButton: true,
          showCloseButton: true,
          everseButtons: true
        }).then(willDelete => {
          if (willDelete.isConfirmed) {
            let data = {
              client_id: this.getLocationId,
              app_id: this.$route.params.app_id,
              app_token: this.$route.params.token
            };
            this.$store
              .dispatch(NEWLOCATIONSTORE, data)
              .then(() => {
                this.$store.commit(SET_LOCATION_ID, "");

                this.$toastr.s("Location change Successfully");
                this.$router.go(-1);
              })
              .catch(() => {});
          }
        });
      }
    },
    locationIndex(index) {
      this.selected_item = this.locations[index];
    },
    fetchLocations() {
      this.dataLoading = true;
      let data = {
        client_id: this.$route.params.id,
        app_id: this.$route.params.app_id,
        app_token: this.$route.params.token
      };
      this.$store
        .dispatch(LOCATIONS, data)
        .then(data => {
          this.locations = data.data;
          this.base_url = data.url;
          this.dataLoading = false;
        })
        .catch(() => {
          this.dataLoading = false;
        });
    }
  }
};
</script>
<style lang="scss" scoped>
// .alert-danger-dim {
// background: #f7cece90 !important;
// }
.bg-light-default {
  background-color: #ffffff;
}
.alert-custom {
  margin-top: 5%;
  z-index: 1;
  background: #dc3545 !important;
}

#btn-footer {
  .btn {
    height: 40px !important;
    float: right;
  }
}
</style>
<style scoped></style>
